import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { achievement } from './data/achievement';
import { about, info } from './data/about';
import { project } from './data/project';
import { menu } from './data/app';
import { skill } from './data/skill';
import { feeds } from './data/extracurricular_feeds';
import { countUps, homeSkills, home_links, quotes } from './data/home';
import { experience } from './data/experience';
import { startingDoha, hanumanChalisa as chaupai, endingDoha } from './data/aum'
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface Stack_Keywords {
    name: string;
}

export interface Details {
    text: string;
    bold: boolean;
}

export interface Projects {
    id: number;
    title: string;
    logo: string;
    link_available: boolean;
    link: string;
    info: string;
    page: string;
    git: boolean;
    stack: Array<Stack_Keywords>;
    keywords: Array<Stack_Keywords>;
    details: Array<Details>;
    screenshots: {
        valid: boolean;
        list: Array<string>;
    };
    selected: boolean;
    category: Array<string>;
}

export interface CountUps {
    countS: number;
    countE: number;
    text: string;
}

export interface Information {
    name: string;
    nick_name: string;
    dob: Date;
    phone: string;
    location: string;
    image_urls: {
        display: string;
        about: string;
    };
}

export interface NavItem {
    title: string;
    link: string;
    icon: any;
}

export interface Skills {
    title: string;
    logo: string;
    isCollapsed: boolean;
    toDoClose: boolean;
    info: string;
}

export interface Quotes {
    text: string;
    by: string;
}

export interface HomeLink {
    href: string;
    iconClass: string;
}

export interface Feeds {
    categories: Array<string>;
    feed: Array<Feed>;
}

export interface Feed {
    link: any;
    category: Array<string>;
    title: string;
}

export interface Companies {
    image: string;
    alt: string;
    link: string;
    name: string;
    role: string;
    duration: string;
    data: Array<Array<string>>;
}

export interface PillSkill {
    link: string;
    text: string;
}

export interface Project {
    categories: Array<string>;
    projects: Array<Projects>;
}

export interface About {
    icon: any;
    html: string;
}

export interface ProgrammingLanguages {
    link: string;
    text: string;
    image: string;
    flag: boolean;
}

export interface HomeSkills {
    MEARN: Array<ProgrammingLanguages>;
    HYBRID: Array<ProgrammingLanguages>;
    LANG: Array<ProgrammingLanguages>;
}

export interface Skill {
    skills: Array<Skills>;
    others: Array<PillSkill>;
    languages: Array<ProgrammingLanguages>;
}

export interface Achievements {
    title: string;
    subtitle: string;
    duration: string;
    description: Array<string>;
}

export interface HanumanChalisa {
    doha_1: string[];
    chaupai: string[];
    doha_2: string[];
}

export interface Achievement {
    images: Array<string>;
    achievements: Array<Achievements>;
}

@Injectable({
    providedIn: 'root',
})
export class DataService {
    // personal information
    info: Information;
    // nav data
    menu: Array<NavItem>;
    // home links
    home_links: Array<HomeLink>;
    // achievements data
    achievement: Achievement;
    // about data
    about: Array<About>;
    // project data
    project: Project;
    // skills
    skill: Skill;
    // extracurricular feeds
    feeds: Feeds;
    // experience companies
    companies: Array<Companies>;
    // quotes for homepage
    quotes: Array<Quotes>;
    // countups for homepage
    countUps: Array<CountUps>;
    // homeskills for homepage
    homeSkills: HomeSkills;
    // hanuman chalisa
    hanumanChalisa: HanumanChalisa;

    constructor(private sanitizer: DomSanitizer) {
        this.info = info;
        this.achievement = achievement;
        this.about = about;
        this.project = project;
        this.home_links = home_links;
        this.skill = skill;
        this.menu = menu;
        this.feeds = this.formatLinkSanitizer();
        this.companies = experience;
        this.quotes = quotes;
        this.countUps = countUps;
        this.countUps[2].countE = this.project.projects.length;
        this.homeSkills = homeSkills;
        this.hanumanChalisa = {
            doha_1: startingDoha,
            chaupai: chaupai,
            doha_2: endingDoha
        };
    }

    throttle = (method: () => void, time) => {
        let flag = true;
        return (...args) => {
            let context = this;
            if (flag) {
                method.apply(context, args);
                flag = false;
                setTimeout(() => {
                    flag = true;
                }, time);
            }
        };
    };

    formatLinkSanitizer = (): Feeds => {
        const local_feeds: Feeds = { ...feeds };
        for (let feed of local_feeds.feed) {
            feed.link = this.sanitizer.bypassSecurityTrustResourceUrl(feed.link);
        }
        return local_feeds;
    };
}
