import { Feed, Feeds } from '../data.service';

export const feeds: Feeds = {
    categories: ['All', 'Vocals', 'Guitar', 'Flute', 'Keyboard'],
    feed: [
        {
            link: 'https://www.youtube.com/embed/SVcWHXXE8oc',
            category: ['All', 'Vocals', 'Guitar'],
            title: 'Sham - Aisha | Acoustic Cover',
        },
        {
            link: 'https://www.youtube.com/embed/4JZ_sMxMJPA',
            category: ['All', 'Flute'],
            title: 'Krrish Movie Flute | Krishna Flute Soundtrack | Flute Cover',
        },
        {
            link: 'https://www.youtube.com/embed/dFjW8QuwP34',
            category: ['All', 'Vocals', 'Guitar'],
            title: 'O Sanam - Lucky Ali | Acoustic Cover',
        },
        {
            link: 'https://www.youtube.com/embed/G8aHh8x_ChY',
            category: ['All', 'Guitar'],
            title: 'Kabhi Jo Badal Barse || Guitar Cover',
        },
        {
            link: 'https://www.youtube.com/embed/D6Wz9CLTOLI',
            category: ['All', 'Flute'],
            title: 'Kabira || Flute Cover',
        },
        {
            link: 'https://www.youtube.com/embed/wTAdYa3TZGs',
            category: ['All', 'Guitar'],
            title: 'Finger Tap Melody || Electric Guitar',
        },
        {
            link: 'https://www.youtube.com/embed/JSqqoSxJADo',
            category: ['All', 'Guitar'],
            title: 'Naagin || Guitar Cover',
        },
        {
            link: 'https://www.youtube.com/embed/KCoCyzN9UpM',
            category: ['All', 'Guitar'],
            title: 'Dhol Jageero Da || Guitar Tune',
        },
        {
            link: 'https://www.youtube.com/embed/5-bwaiQO62E',
            category: ['All', 'Flute'],
            title: 'Aaoge Jab Tum O Saajna || Flute Cover',
        },
        {
            link: 'https://www.youtube.com/embed/KtY_LUJH4jg',
            category: ['All', 'Flute'],
            title: 'Ajeeb Dastaan Hai Ye || Flute-Boxing Cover',
        },
        {
            link: 'https://www.youtube.com/embed/tGcgzDplh0A',
            category: ['All', 'Guitar'],
            title: 'Kabhi Jo Badal Barse || Guitar Cover',
        },
        {
            link: 'https://www.youtube.com/embed/pCkfD8AFHic',
            category: ['All', 'Guitar'],
            title: 'Back in Black - ACDC || Electric Guitar',
        },
        {
            link: 'https://www.youtube.com/embed/NyIo9A_yTTU',
            category: ['All', 'Guitar'],
            title: 'Sadda Haq Intro - Rockstar || Electric guitar',
        },
        {
            link: 'https://www.youtube.com/embed/rwPcDqM_SZw',
            category: ['All', 'Keyboard'],
            title: 'Numb - Linkin Park || Piano Cover',
        },
        {
            link: 'https://www.youtube.com/embed/o4M3SPnuDlc',
            category: ['All', 'Keyboard'],
            title: 'Heavy - Linkin Park || Piano Cover',
        },
        {
            link: 'https://www.youtube.com/embed/XnkTdX81JRs',
            category: ['All', 'Flute'],
            title: 'Zara Zara Bahekta Hai || Flute Cover',
        },
        {
            link: 'https://www.youtube.com/embed/aTNWc4uxqp4',
            category: ['All', 'Flute'],
            title: 'Abhi Mujhme Kahi - Sonu Nigam || Flute Cover',
        },
        {
            link: 'https://www.youtube.com/embed/gkxLdE4CDBo',
            category: ['All', 'Guitar'],
            title: 'Fade to Black - Metallica || Guitar Cover',
        },
        {
            link: 'https://www.youtube.com/embed/MtV8zNQ7UE8',
            category: ['All', 'Guitar'],
            title: 'Faded - Alan Walker || Guitar Cover',
        },
        {
            link: 'https://www.youtube.com/embed/vCbbSByvbwc',
            category: ['All', 'Guitar'],
            title: 'Super Mario | Guitar Cover',
        },
    ],
};
