import { CountUps, HomeLink, HomeSkills, Quotes } from '../data.service';

export const home_links: Array<HomeLink> = [
    {
        href: 'https://twitter.com/TanayToshniwal',
        iconClass: 'fa-twitter',
    },
    {
        href: 'https://github.com/tanaytoshniwal',
        iconClass: 'fa-github',
    },
    {
        href: 'https://www.youtube.com/c/TanayToshniwal',
        iconClass: 'fa-youtube yt',
    },
    {
        href: 'https://www.linkedin.com/in/tanay-toshniwal/',
        iconClass: 'fa-linkedin',
    },
    {
        href: 'https://www.instagram.com/tanaytoshniwal/',
        iconClass: 'fa-instagram',
    },
    {
        href: 'https://www.facebook.com/tanay.toshniwal',
        iconClass: 'fa-facebook-f',
    },
    {
        href: 'https://mysticalcoder.com/',
        iconClass: 'fa-pencil',
    },
    {
        href: 'mailto:contact@tanaytoshniwal.me',
        iconClass: 'fa-envelope',
    },
];

export const quotes: Array<Quotes> = [
    {
        text: 'Talk is cheap. Show me the code.',
        by: 'Linus Torvalds',
    },
    {
        text: "If you're changing the world, you're working on important things. You're excited to get up in the morning.",
        by: 'Larry Page',
    },
    {
        text: 'Because the people who are crazy enough to think they can change the world are the ones who do.',
        by: 'Steve Jobs',
    },
    {
        text: 'Try not to become a man of SUCCESS but rather try to become a man of VALUE.',
        by: 'Albert Einstein',
    },
    {
        text: 'Any fool can write code that a computer can understand. Good programmers write code that humans can understand.',
        by: 'Martin Fowler',
    },
    {
        text: "I'm not a great programmer; I'm just a good programmer with great habits.",
        by: 'Kent Beck',
    },
];

export const countUps: Array<CountUps> = [
    {
        countS: 0,
        countE: 120000,
        text: 'Lines of code',
    },
    {
        countS: 0,
        countE: 2180,
        text: 'Cups of coffee',
    },
    {
        countS: 0,
        countE: 0,
        text: 'Projects completed',
    },
];

export const homeSkills: HomeSkills = {
    MEARN: [
        {
            text: 'Next.js',
            image: 'assets/images/next.png',
            flag: false,
            link: undefined,
        },
        {
            text: 'React',
            image: 'assets/images/react.png',
            flag: false,
            link: undefined,
        },
        {
            text: 'Angular',
            image: 'assets/images/angular.png',
            flag: false,
            link: undefined,
        },
        {
            text: 'Mongo DB',
            image: 'assets/images/mongo.png',
            flag: false,
            link: undefined,
        },
        {
            text: 'Express.js',
            image: 'assets/images/express.png',
            flag: false,
            link: undefined,
        },
        {
            text: 'Node.js',
            image: 'assets/images/node.png',
            flag: false,
            link: undefined,
        },
    ],
    HYBRID: [
        {
            text: 'React Native',
            image: 'assets/images/react_native.png',
            flag: false,
            link: undefined,
        },
        {
            text: 'Ionic Framework',
            image: 'assets/images/ionic.png',
            flag: false,
            link: undefined,
        },
    ],
    LANG: [
        {
            link: 'https://www.typescriptlang.org/',
            text: 'TypeScript',
            image: 'assets/languages/typescript.png',
            flag: false,
        },
        {
            link: 'http://es6-features.org/',
            text: 'JavaScript(ES6)',
            image: 'assets/languages/javascript.png',
            flag: false,
        },
        {
            link: 'https://en.wikipedia.org/wiki/C%2B%2B',
            text: 'C++',
            image: 'assets/languages/cpp.png',
            flag: false,
        },
    ],
};
