import { faBriefcase, faCloudDownloadAlt, faCode, faCommentAlt, faHome, faLaptopCode, faMusic, faOm, faPencilAlt, faTrophy, faUser } from '@fortawesome/free-solid-svg-icons';
import { NavItem } from '../data.service';

export const menu: Array<NavItem> = [
    {
        title: 'Home',
        link: 'home',
        icon: faHome,
    },
    {
        title: ' About me',
        link: 'about',
        icon: faUser,
    },
    {
        title: ' Blogs',
        link: 'blogs',
        icon: faPencilAlt,
    },
    {
        title: 'Technical Skills',
        link: 'skills',
        icon: faLaptopCode,
    },
    {
        title: 'Work Experience',
        link: 'experience',
        icon: faBriefcase,
    },
    {
        title: 'Projects & code',
        link: 'projects',
        icon: faCode,
    },
    {
        title: 'Achievements',
        link: 'achievements',
        icon: faTrophy,
    },
    {
        title: 'Message me',
        link: 'contact',
        icon: faCommentAlt,
    },
    {
        title: 'Extracurricular',
        link: 'extracurricular',
        icon: faMusic,
    },
    {
        title: 'My Resume',
        link: 'resume',
        icon: faCloudDownloadAlt,
    },
    // {
    //     title: 'Aum',
    //     link: 'aum',
    //     icon: faOm,
    // },
];
