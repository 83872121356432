import { Component } from '@angular/core';
import { ThemeService } from '../theme.service';

@Component({
    selector: 'app-youtube',
    templateUrl: './youtube.component.html',
    styleUrls: ['./youtube.component.css'],
})
export class YoutubeComponent {
    constructor(public themeService: ThemeService) {}
}
