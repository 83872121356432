import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { YoutubeComponent } from './youtube/youtube.component';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((module) => module.HomeModule),
    },
    {
        path: 'about',
        loadChildren: () => import('./about/about.module').then((module) => module.AboutModule),
    },
    {
        path: 'skills',
        loadChildren: () => import('./skills/skills.module').then((module) => module.SkillsModule),
    },
    {
        path: 'experience',
        loadChildren: () => import('./experience/experience.module').then((module) => module.ExperienceModule),
    },
    {
        path: 'projects',
        loadChildren: () => import('./projects/projects.module').then((module) => module.ProjectsModule),
    },
    {
        path: 'achievements',
        loadChildren: () => import('./achievements/achievements.module').then((module) => module.AchievementsModule),
    },
    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then((module) => module.ContactModule),
    },
    {
        path: 'extracurricular',
        loadChildren: () => import('./extracurricular/extracurricular.module').then((module) => module.ExtracurricularModule),
    },
    {
        path: 'resume',
        loadChildren: () => import('./resume/resume.module').then((module) => module.ResumemoduleModule),
    },
    {
        path: 'aum',
        loadChildren: () => import('./aum/aum.module').then((module) => module.AumModule),
    },
    {
        path: 'project/:id',
        redirectTo: 'projects/project/:id',
        pathMatch: 'full',
    },
    {
        path: 'PROJECT/:id',
        redirectTo: 'projects/project/:id',
        pathMatch: 'full',
    },
    {
        path: 'youtube',
        component: YoutubeComponent,
        resolve: {
            url: 'externalUrlRedirectResolver',
        },
        data: {
            externalUrl: 'https://www.youtube.com/c/TanayToshniwal',
        },
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '**', redirectTo: '/home' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
