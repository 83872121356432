import { Achievement } from '../data.service';

export const achievement: Achievement = {
    images: [
        'assets/images/1h.jpg',
        'assets/images/h1.jpg',
        'assets/images/h2.jpg',
        'assets/images/h3.jpg',
        'assets/images/11.jpg',
        'assets/images/h5.jpg',
        'assets/images/h6.jpg',
    ],
    achievements: [
        {
            title: 'Google Full Time Software Engineer',
            subtitle: '3 Technical Rounds',
            duration: 'October 2019',
            description: [
                'Interviewed with Google, India for Full Time Role 2019.',
                '3 Rounds include: Resume Screening, Telephonic Quiz & Hangout Interview.',
            ],
        },
        {
            title: 'Google Code Jam 2019',
            subtitle: 'Cleared Qualification Round',
            duration: 'April 2019',
            description: [
                'Qualified for Round 1 of Google Code Jam 2016.',
                'My Rank was <strong>10551</strong> of <strong>27610</strong> people who qualified for Round 1 from all over the world.',
            ],
        },
        {
            title: 'Microsoft Internship',
            subtitle: 'Final Interview Round',
            duration: 'March 2019',
            description: [
                'Got selected in online Competitive Coding Round.',
                'Cleared Onsite Pen-paper Test held at <strong>VIT Campus, Chennai</strong>.',
                'One of the top 20 students who got selected among 150 students who cleared the online coding round.',
            ],
        },
        {
            title: 'Hitsathon v2.0 - Hindustan University Hackathon',
            subtitle: 'Winner',
            duration: 'August 2018',
            description: [
                'Responsibility: <strong>Team leader (team of 4)</strong>.',
                'Built <a href="https://github.com/tanaytoshniwal/Skin-Sense" target="_blank">SkinSense</a> : Skin Cancer Classification Android Application.',
                'Built using Android Studio, Tensorflow and Firebase Services.',
                'Venue: Hindustan University, Chennai.',
            ],
        },
        {
            title: 'YouStart FullStack Bootcamp 2019',
            subtitle: 'Featured in Top Projects',
            duration: 'March 2020',
            description: [
                'Two projects were featured in Youstart Labs Video.',
                'Projects include: <a routerLink="/projects/project/5">OnlineDiagnosis</a> & <a routerLink="/projects/project/6">MusicSearch</a>.',
            ],
        },
        {
            title: 'Code Enigma',
            subtitle: 'Semi-Finals',
            duration: 'March 2018',
            description: ['Software Association organized Coding Competition.', 'Made it to the semi-final round.'],
        },
        {
            title: 'Semi Colon',
            subtitle: 'Semi-Finals',
            duration: 'Feburary 2017',
            description: ['IT Association organized Coding Competition.', 'Made it to the semi-final round.'],
        },
        {
            title: 'Trinity College, London',
            subtitle: '6 credits in Plectrum Guitar - Grade 1',
            duration: 'January 2014',
            description: [
                'Interviewed by Professor <a href="https://www.linkedin.com/in/bonnie-bradley-bailey-24526915/" target="_blank">Bonnie Bradley Bailey</a> of Trinity College, London.',
                'Scored 87/100 in Plectrum Guitar Grade 1.',
            ],
        },
        {
            title: 'iKen Scientifica',
            subtitle: 'National Finals',
            duration: 'June 2012',
            description: ['Made it to the National Finals Round of iKen Scientifica.', 'Represented my school, Maheshwari Public School, Jaipur.'],
        },
    ],
};
