import { About, Information } from '../data.service';
import { faUserGraduate, faSchool, faHome, faLaptopCode, faUserTie, faMusic } from '@fortawesome/free-solid-svg-icons';

export const about: Array<About> = [
    {
        icon: faUserTie,
        html: 'I\'m currently working as <strong>Software Development Engineer 1</strong> at <a href="https://blinkit.com" target="_blank">Blinkit</a>, and a Freelance Developer.',
    },
    {
        icon: faUserGraduate,
        html: 'I\'m a <strong>Computer Science Engineer</strong>. I did my Bachelor\'s in Computer Science & Engineering from <a href="http://www.srmuniv.ac.in/" target="_blank">SRM Institute of Science and Technology</a>, Chennai in 2020.',
    },
    {
        icon: faSchool,
        html: 'I completed my schooling from <a href="http://mpsjaipur.com/" target="_blank">Maheshwari Public School, Jaipur</a> in 2016, with <strong>Informatics Practices</strong> as an optional subject.',
    },
    {
        icon: faHome,
        html: 'Born and brought up in the very beautiful Pink City, <a href="https://en.wikipedia.org/wiki/Jaipur" target="_blank">Jaipur</a>.',
    },
    {
        icon: faLaptopCode,
        html: "I'm a <strong>Full Stack Developer</strong> in <strong>MEAN/MERN Stack</strong>, an <strong>App Developer</strong> and a Programmer, who loves to learn and try new tools & technologies.",
    },
    {
        icon: faMusic,
        html: "Apart from the technical stuff, I Love to play <strong>Musical Instruments</strong>, <strong>Biking</strong>, and solve <strong>Rubik's Cube</strong>.",
    },
];

export const info: Information = {
    name: 'Tanay Toshniwal',
    nick_name: 'Tony',
    phone: '',
    dob: new Date('1998-11-03'),
    location: 'Jaipur, India',
    image_urls: {
        display: 'assets/images/tanay_toshniwal.png',
        about: 'assets/images/tanay-toshniwal.jpg',
    },
};
