export const startingDoha: string[] = [
    'श्रीगुरु चरन सरोज रज निज मनु मुकुरु सुधारि ।',
    'बरनउँ रघुवर बिमल जसु जो दायकु फल चारि ॥',
    'बुद्धिहीन तनु जानिके सुमिरौं पवन-कुमार ।',
    'बल बुधि विद्या देहु मोहिं हरहु कलेस विकार ॥',
];

export const hanumanChalisa: string[] = [
    'जय हनुमान ज्ञान गुन सागर ।',
    'जय कपीस तिहुँ लोक उजागर ॥१॥',
    'राम दूत अतुलित बल धामा ।',
    'अंजनी-पुत्र पवनसुत नामा ॥२॥',
    'महावीर विक्रम बजरंगी ।',
    'कुमति निवार सुमति के सङ्गी ॥३॥',
    'कंचन वरन विराज सुवेसा ।',
    'कानन कुण्डल कुंचित केसा ॥४॥',
    'हाथ वज्र औ ध्वजा विराजे ।',
    'काँधे मूँज जनेउ साजै ॥५॥',
    'शंकर सुवन केसरीनन्दन ।',
    'तेज प्रताप महा जग बंधन ॥६॥',
    'विद्यावान गुनी अति चातुर ।',
    'राम काज करिबे को आतुर ॥७॥',
    'प्रभु चरित्र सुनिबे को रसिया ।',
    'राम लखन सीता मन बसिया ॥८॥',
    'सूक्ष्म रूप धरि सियहिं दिखावा ।',
    'विकत रूप धरि लंक जरावा ॥९॥',
    'भीम रूप धरि असुर सँहारे ।',
    'रामचन्द्र के काज सँवारे ॥१०॥',
    'लाय संजीवन लखन जियाये ।',
    'श्रीरघुवीर हरषि उर लाये ॥११॥',
    'रघुपति कीन्ही बहुत बड़ाई ।',
    'तुम मम प्रिय भरतहि सम भाई ॥१२॥',
    'सहस बदन तुह्मारो जस गावैं ।',
    'अस कहि श्रीपति कण्ठ लगावैं ॥१३॥',
    'सनकादिक ब्रह्मादि मुनीसा ।',
    'नारद सारद सहित अहीसा ॥१४॥',
    'यम कुबेर दिगपाल जहाँ ते ।',
    'कवि कोविद कहि सके कहाँ ते ॥१५॥',
    'तुम उपकार सुग्रीवहिं कीन्हा ।',
    'राम मिलाय राज पद दीह्ना ॥१६॥',
    'तुम्हारो मन्त्र विभीषण माना ।',
    'लंकेश्वर भए सब जग जाना ॥१७॥',
    'जुग सहस्र जोजन पर भानु ।',
    'लील्यो ताहि मधुर फल जानू ॥१८॥',
    'प्रभु मुद्रिका मेलि मुख माहीं ।',
    'जलधि लाँघि गये अचरज नाहीं ॥१९॥',
    'दुर्गम काज जगत के जेते ।',
    'सुगम अनुग्रह तुम्हारे तेते ॥२०॥',
    'राम दुआरे तुम रखवारे ।',
    'होत न आज्ञा बिनु पैसारे ॥२१॥',
    'सब सुख लहै तुम्हारी सरना ।',
    'तुम रक्षक काहू को डर ना ॥२२॥',
    'आपन तेज सम्हारो आपै ।',
    'तीनों लोक हाँक तें काँपै ॥२३॥',
    'भूत पिशाच निकट नहिं आवै ।',
    'महावीर जब नाम सुनावै ॥२४॥',
    'नासै रोग हरै सब पीड़ा ।',
    'जपत निरन्तर हनुमत बीरा ॥२५॥',
    'संकट तें हनुमान छुड़ावै ।',
    'मन क्रम वचन ध्यान जो लावै ॥२६॥',
    'सब पर राम तपस्वी राजा ।',
    'तिन के काज सकल तुम साजा ॥२७॥',
    'और मनोरथ जो कोई लावै ।',
    'सोई अमित जीवन फल पावै ॥२८॥',
    'चारों जुग परताप तुम्हारा ।',
    'है परसिद्ध जगत उजियारा ॥२९॥',
    'साधु सन्त के तुम रखवारे ।',
    'असुर निकन्दन राम दुलारे ॥३०॥',
    'अष्टसिद्धि नौ निधि के दाता ।',
    'अस वर दीन जानकी माता ॥३१॥',
    'राम रसायन तुम्हारे पासा ।',
    'सदा रहो रघुपति के दासा ॥३२॥',
    'तुम्हारे भजन राम को पावै ।',
    'जनम जनम के दुख बिसरावै ॥३३॥',
    'अन्त काल रघुवर पुर जाई ।',
    'जहाँ जन्म हरिभक्त कहाई ॥३४॥',
    'और देवता चित्त न धरई ।',
    'हनुमत सेइ सर्व सुख करई ॥३५॥',
    'संकट कटै मिटै सब पीड़ा ।',
    'जो सुमिरै हनुमत बलबीरा ॥३६॥',
    'जय जय जय हनुमान गोसाईं ।',
    'कृपा करहु गुरुदेव की नाईं ॥३७॥',
    'जो शत बार पाठ कर कोई ।',
    'छूटहि बन्दि महा सुख होई ॥३८॥',
    'जो यह पढ़ै हनुमान चालीसा ।',
    'होय सिद्धि साखी गौरीसा ॥३९॥',
    'तुलसीदास सदा हरि चेरा ।',
    'कीजै नाथ हृदय महँ डेरा ॥४०॥',
];

export const endingDoha: string[] = ['पवनतनय संकट हरन', 'मंगल मूरति रूप ।', 'राम लखन सीता सहित', 'हृदय बसहु सुर भूप ॥'];
