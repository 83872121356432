import { Project } from '../data.service';

export const project: Project = {
    categories: [
        'All',
        'Android',
        'Android: Fullstack',
        'Angular',
        'Data Structures & Algorithms',
        'Hybrid App',
        'Hybrid App: Fullstack',
        'Ionic Framework',
        'Java SE',
        'Machine Learning',
        'npmjs Module',
        'Python',
        'React',
        'React Native',
        'Web',
        'Web: Fullstack',
    ],
    projects: [
        {
            id: 1,
            selected: true,
            link_available: true,
            category: ['Data Structures & Algorithms'],
            title: 'Data-Structures-Algorithms',
            logo: 'assets/images/competitive-programming.png',
            info: 'Implementations of Data Structures and Algorithms, Solutions of competitive programming problems, challenges and Interview Questions',
            git: true,
            page: 'project/1',
            link: 'https://github.com/tanaytoshniwal/Data-Structures-Algorithms',
            stack: [{ name: 'C' }, { name: 'C++' }, { name: 'Java SE' }, { name: 'Python' }],
            keywords: [
                { name: 'algorithms' },
                { name: 'data-structures' },
                { name: 'competitive-programming' },
                { name: 'competitive-coding' },
                { name: 'coding-challenges' },
                { name: 'interview-questions' },
            ],
            details: [
                {
                    text: 'I implemented popular Data Structures and Algorithms question and Competitive Programming Questions in C, C++, Java and Python3',
                    bold: true,
                },
                { text: 'Competitive Coding Questions', bold: false },
                { text: 'Interview Preparation', bold: false },
                { text: 'Linked List', bold: false },
                { text: 'Binary Trees', bold: false },
                { text: 'Binary Search Trees', bold: false },
                { text: 'Dynamic Programming', bold: false },
                { text: 'Searching Algorithms', bold: false },
                { text: 'Sorting Algorithms', bold: false },
                { text: 'Strings and Arrays', bold: false },
            ],
            screenshots: {
                valid: false,
                list: [],
            },
        },
        {
            id: 2,
            selected: true,
            link_available: true,
            category: ['Ionic Framework', 'Hybrid App'],
            title: 'Shoppr',
            logo: 'assets/images/woocommerce.jpg',
            link: 'https://store.enappd.com/product/ionic-4-woocommerce-starter/',
            info: 'Ionic 4 starter application for E-Commerce App. Built during Internship at Enappd',
            git: false,
            page: 'project/2',
            stack: [{ name: 'Ionic 4' }, { name: 'Firebase Authentication' }, { name: 'Javascript ES6' }],
            keywords: [{ name: 'Material Design' }, { name: 'Hybrid Mobile Application' }, { name: 'E-Commerce Application' }],
            details: [
                { text: 'Made IOS & Android version of ”Wish”', bold: true },
                { text: 'A complete Ionic 4 Hybrid starter application', bold: false },
                { text: 'Sold 60+ items at Enappd Store', bold: false },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/woocommerce.jpg'],
            },
        },
        {
            id: 31,
            link_available: false,
            selected: true,
            category: ['Hybrid App', 'React Native', 'Hybrid App: Fullstack'],
            title: 'VisitingCards',
            logo: 'assets/screenshots/VisitingCards/Screenshot9.jpg',
            link: 'https://github.com/tanaytoshniwal/VisitingCard',
            info: 'React Native application for generating and sharing Visiting Cards. Built during Internship at Saachi Technologies.',
            git: false,
            page: 'project/31',
            stack: [
                { name: 'React Native' },
                { name: 'Firestore' },
                { name: 'Firebase Authentication' },
                { name: 'Javascript ES6' },
                { name: 'Node.js' },
            ],
            keywords: [
                { name: 'Material Design' },
                { name: 'Hybrid Mobile Application' },
                { name: 'React Native Application' },
                { name: 'REST APIs' },
            ],
            details: [
                {
                    text: 'Made a React Native Full Stack Application for generating and sharing Visiting Cards.',
                    bold: true,
                },
                {
                    text: "Implemented QR Code generation using users id that can be used by in-app scanner for adding to user's contacts.",
                    bold: false,
                },
                {
                    text: "Implemented Responsive design of Visiting card that renders dynamically from JSON Object making it easy to edit a it's details.",
                    bold: false,
                },
                {
                    text: "Implemented efficient Contacts filtering based on user's contacts list.",
                    bold: false,
                },
                {
                    text: "Dynamic updating of user's contacts and their current Visiting Cards.",
                    bold: false,
                },
                {
                    text: 'Used Firebase Social Authentication for authenticating users.',
                    bold: false,
                },
                { text: 'Used Firebase Firestore for NoSQL database', bold: false },
                { text: 'Used Node.js and Express.js for REST APIs.', bold: false },
            ],
            screenshots: {
                valid: true,
                list: [
                    'assets/screenshots/VisitingCards/Screenshot1.jpg',
                    'assets/screenshots/VisitingCards/Screenshot2.jpg',
                    'assets/screenshots/VisitingCards/Screenshot3.jpg',
                    'assets/screenshots/VisitingCards/Screenshot4.jpg',
                    'assets/screenshots/VisitingCards/Screenshot5.jpg',
                    'assets/screenshots/VisitingCards/Screenshot6.jpg',
                    'assets/screenshots/VisitingCards/Screenshot7.jpg',
                    'assets/screenshots/VisitingCards/Screenshot8.jpg',
                    'assets/screenshots/VisitingCards/Screenshot9.jpg',
                    'assets/screenshots/VisitingCards/Screenshot10.jpg',
                    'assets/screenshots/VisitingCards/Screenshot11.jpg',
                    'assets/screenshots/VisitingCards/Screenshot12.jpg',
                    'assets/screenshots/VisitingCards/Screenshot13.jpg',
                ],
            },
        },
        {
            id: 34,
            link_available: true,
            selected: true,
            page: 'project/34',
            title: 'Ananta Elite Membership',
            category: ['React', 'Web', 'Web: Fullstack'],
            logo: 'assets/screenshots/AnantaEliteMembership/s1.jpg',
            info: 'Ananta Hotels and Resorts Membership management application.',
            git: false,
            link: 'https://ananta-membership.web.app/',
            stack: [{ name: 'React.js' }, { name: 'Firebase' }],
            keywords: [{ name: 'Firebase Authentication' }, { name: 'Firebase Firestore' }, { name: 'Authorization' }],
            details: [
                {
                    text: 'Worked on membership management website built using React.js, Redux, Firebase Firestore and Firebase Authentication.',
                    bold: true,
                },
                {
                    text: 'Implemented efficient authorization for 5 admins, 9 staff members and 10K+ customers to access the website.',
                    bold: false,
                },
                {
                    text: 'Admins can manage their plans and benefits, can add/modify any customer and can view redeemed benefits filtered date-wise.',
                    bold: false,
                },
                {
                    text: "Staff Members can redeem any customer's benefits at their resorts or hotels on request.",
                    bold: false,
                },
                {
                    text: 'Customers can view their membership details and their available/redeemed benefits.',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: [
                    'assets/screenshots/AnantaEliteMembership/s1.jpg',
                    'assets/screenshots/AnantaEliteMembership/s2.jpg',
                    'assets/screenshots/AnantaEliteMembership/s3.jpg',
                    'assets/screenshots/AnantaEliteMembership/s4.jpg',
                    'assets/screenshots/AnantaEliteMembership/s5.jpg',
                    'assets/screenshots/AnantaEliteMembership/s6.jpg',
                    'assets/screenshots/AnantaEliteMembership/s7.jpg',
                    'assets/screenshots/AnantaEliteMembership/s8.jpg',
                    'assets/screenshots/AnantaEliteMembership/s9.jpg',
                ],
            },
        },
        {
            id: 32,
            link_available: false,
            selected: false,
            category: ['Hybrid App', 'React Native', 'Hybrid App: Fullstack'],
            title: 'StockApp',
            logo: 'assets/screenshots/StockApp/ss0.jpeg',
            link: 'https://github.com/tanaytoshniwal/StockApp',
            info: 'React Native application for shopkeepers to track details of products and vendors. Built during Internship at Saachi Technologies.',
            git: false,
            page: 'project/32',
            stack: [{ name: 'React Native' }, { name: 'SQLite' }, { name: 'Javascript ES6' }, { name: 'Node.js' }],
            keywords: [
                { name: 'Material Design' },
                { name: 'Hybrid Mobile Application' },
                { name: 'React Native Application' },
                { name: 'REST APIs' },
            ],
            details: [
                {
                    text: 'Made a React Native Full Stack Application for shopkeepers to track details of products and vendors.',
                    bold: true,
                },
                {
                    text: 'Implemented QR Code generation using users id that can be used by in-app scanner for getting the product details.',
                    bold: false,
                },
                {
                    text: 'Implemented Responsive design with custom UI components',
                    bold: false,
                },
                {
                    text: 'Used SQLite for relational database to implement offline storage',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: [
                    'assets/screenshots/StockApp/ss0.jpeg',
                    'assets/screenshots/StockApp/ss1.jpeg',
                    'assets/screenshots/StockApp/ss2.jpeg',
                    'assets/screenshots/StockApp/ss3.jpeg',
                    'assets/screenshots/StockApp/ss4.jpeg',
                    'assets/screenshots/StockApp/ss5.jpeg',
                    'assets/screenshots/StockApp/ss6.jpeg',
                    'assets/screenshots/StockApp/ss7.jpeg',
                    'assets/screenshots/StockApp/ss8.jpeg',
                ],
            },
        },
        {
            id: 3,
            page: 'project/3',
            link_available: true,
            category: ['Ionic', 'Hybrid App', 'Hybrid App: Fullstack'],
            title: 'TrackMe',
            selected: false,
            logo: 'assets/images/trackme.jpg',
            info: 'Hybrid Application to track your daily activities like tasks, remainders, notes, transactions, etc. Built using Ionic 4',
            git: true,
            link: 'https://github.com/tanaytoshniwal/TrackMe',
            stack: [{ name: 'Ionic 4' }, { name: 'TypeScript' }, { name: 'Firebase' }, { name: 'Firestore' }],
            keywords: [{ name: 'O-Auth' }, { name: 'Hybrid Mobile Application' }],
            details: [
                {
                    text: 'An application with features like Todo-list, remainders, notes and transactions to keep a track and stay consistent',
                    bold: true,
                },
                {
                    text: 'Todo List : A simple todo list categorized specially for daily, weekly, monthly and yearly tasks',
                    bold: false,
                },
                {
                    text: 'Transactions Statistics : Track your daily transactions in a user interactive way',
                    bold: false,
                },
                {
                    text: 'Notes : Keep your personal notes safe and handy',
                    bold: false,
                },
                {
                    text: 'Remainders : Add remainders and never forget something important',
                    bold: false,
                },
                { text: 'Fitness : Feature under development', bold: false },
            ],
            screenshots: {
                valid: true,
                list: [
                    'assets/screenshots/TrackMe/screenShot_1.jpg',
                    'assets/screenshots/TrackMe/screenShot_2.jpg',
                    'assets/screenshots/TrackMe/screenShot_3.jpg',
                    'assets/screenshots/TrackMe/screenShot_4.jpg',
                    'assets/screenshots/TrackMe/screenShot_5.jpg',
                    'assets/screenshots/TrackMe/screenShot_6.jpg',
                    'assets/screenshots/TrackMe/screenShot_7.jpg',
                    'assets/screenshots/TrackMe/screenShot_8.jpg',
                    'assets/screenshots/TrackMe/screenShot_9.jpg',
                    'assets/screenshots/TrackMe/screenShot_10.jpg',
                    'assets/screenshots/TrackMe/screenShot_11.jpg',
                ],
            },
        },
        {
            id: 6,
            link_available: true,
            page: 'project/6',
            category: ['React', 'Web'],
            selected: true,
            title: 'MusicSearch',
            logo: 'assets/images/MusicSearch.png',
            info: 'A Music Searching Web App built using React that uses iTunes API for Searching and Lyrics.ovh API for finding the lyrics of the song.',
            git: true,
            link: 'https://github.com/TheDevCorp/MusicSearch',
            stack: [{ name: 'React.js' }, { name: 'Ant Design' }],
            keywords: [{ name: 'Material Design Lite' }, { name: 'API' }],
            details: [
                {
                    text: 'A Music Searching Web App built using React that uses iTunes API for Searching and Lyrics.ovh API for finding the lyrics of the song.',
                    bold: true,
                },
                { text: 'Featured as Top Project by Youstart Labs.', bold: true },
                {
                    text: 'The application uses two APIs, one for the Music Search and Other for finding the Lyrics',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/MusicSearch.png'],
            },
        },
        {
            id: 5,
            link_available: true,
            selected: true,
            page: 'project/5',
            title: 'OnlineDiagnosis',
            category: ['React', 'Web'],
            logo: 'assets/images/OnlineDiagnosis.png',
            info: "A React application that uses Infermedica's Api for Online Diagnosis",
            git: true,
            link: 'https://github.com/TheDevCorp/OnlineDiagnosis',
            stack: [{ name: 'React.js' }, { name: 'Ant Design' }],
            keywords: [{ name: 'Material Design Lite' }, { name: 'API' }],
            details: [
                {
                    text: "A React application that uses Infermedica's Api for Online Diagnosis.",
                    bold: true,
                },
                { text: 'Featured as Top Project by Youstart Labs.', bold: true },
                {
                    text: 'The application uses two APIs, one for the symptoms data and another for diagnosis',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/OnlineDiagnosis.png'],
            },
        },
        {
            id: 33,
            link_available: true,
            selected: false,
            page: 'project/33',
            title: 'BurgerBuilder',
            category: ['React', 'Web', 'Web: Fullstack'],
            logo: 'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_2.jpg',
            info: 'A Burger Builder application built using React.',
            git: true,
            link: 'https://github.com/tanaytoshniwal/BurgerBuilder',
            stack: [{ name: 'React.js' }, { name: 'Firebase' }],
            keywords: [{ name: 'Firebase Authentication' }, { name: 'Firebase Realtime Database' }],
            details: [
                { text: 'A Burger Builder application built using React.', bold: true },
                {
                    text: 'User can build their own burger from the interactive UI and place their order.',
                    bold: false,
                },
                {
                    text: 'Used Firebase Authentication to authenticate user and display their orders.',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: [
                    'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_1.jpg',
                    'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_2.jpg',
                    'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_3.jpg',
                    'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_4.jpg',
                    'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_5.jpg',
                    'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_6.jpg',
                    'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_7.jpg',
                    'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_8.jpg',
                    'assets/screenshots/BurgerBuilder/BurgerBuilder_ss_9.jpg',
                ],
            },
        },
        {
            id: 30,
            selected: false,
            link_available: true,
            title: 'IAmBored',
            category: ['React Native', 'Hybrid App'],
            logo: 'assets/screenshots/IAmBored/iambored.gif',
            info: 'A React Native application to get Random memes.',
            link: 'https://github.com/tanaytoshniwal/IAmBored',
            page: 'project/30',
            git: true,
            stack: [{ name: 'React Native' }, { name: 'axios' }],
            keywords: [{ name: 'React Native' }, { name: 'API Calling' }, { name: 'Lazy Loading' }, { name: 'Custom Loaders' }],
            details: [
                { text: 'A React Native application to get Random memes.', bold: true },
                {
                    text: 'The app displays random memes fetched from API on a single click.',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/screenshots/IAmBored/iambored.gif'],
            },
        },
        {
            id: 29,
            selected: false,
            link_available: true,
            category: ['React Native', 'Android', 'npmjs Module'],
            title: 'react-native-custom-ui-toast',
            logo: 'assets/screenshots/ReactNativeCustomUIToast/react-native-custom-ui-toast.gif',
            info: 'Custom Toast Library built for React Native Applications. Module is hosted live on npmjs.com',
            link: 'https://www.npmjs.com/package/react-native-custom-ui-toast',
            page: 'project/29',
            git: false,
            stack: [{ name: 'React Native' }, { name: 'Android Studio' }, { name: 'Android SDK' }],
            keywords: [{ name: 'React Native' }, { name: 'Custom Module' }, { name: 'Toast' }, { name: 'Android' }],
            details: [
                {
                    text: 'Custom Toast Library built for React Native Applications.',
                    bold: true,
                },
                {
                    text: 'The module is hosted on npmjs.com and can be used to display custom toast.',
                    bold: false,
                },
                {
                    text: 'Display toast with user defined position, and other UI styles.',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/screenshots/ReactNativeCustomUIToast/react-native-custom-ui-toast.gif'],
            },
        },
        {
            id: 28,
            selected: false,
            title: 'ColorPicker',
            link_available: true,
            category: ['Angular', 'Web'],
            logo: 'assets/screenshots/ColorPicker/ColorPicker3.jpg',
            info: 'A Simple Color Picker Web-App that could be used by developers for UI Development.',
            link: 'https://github.com/TheDevCorp/ColorPicker',
            page: 'project/28',
            git: true,
            stack: [{ name: 'Angular' }],
            keywords: [{ name: 'Color Picker' }, { name: 'UI Development' }, { name: 'UI Designing' }],
            details: [
                {
                    text: 'A Simple Color Picker Web-App that could be used by developers for UI Development.',
                    bold: true,
                },
                {
                    text: 'A curated list of selected colors that a developer can use in his/her project.',
                    bold: false,
                },
                {
                    text: 'Provides HEX codes and RGB values with just one click.',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: [
                    'assets/screenshots/ColorPicker/ColorPicker1.jpg',
                    'assets/screenshots/ColorPicker/ColorPicker2.jpg',
                    'assets/screenshots/ColorPicker/ColorPicker3.jpg',
                ],
            },
        },
        {
            id: 27,
            selected: false,
            link_available: true,
            category: ['Angular', 'Web'],
            title: 'TicTacToe-PWA',
            logo: 'assets/screenshots/T3PWA/t3_3.jpg',
            info: 'A Tic-Tac-Toe PWA(Progressive Web App) implementation using Angular.',
            link: 'https://github.com/tanaytoshniwal/TicTacToe-PWA',
            page: 'project/27',
            git: true,
            stack: [{ name: 'Angular' }, { name: 'Nebular' }],
            keywords: [{ name: 'Tic-Tac-Toe' }, { name: 'PWA' }, { name: 'Game Algorithms' }],
            details: [
                {
                    text: 'A Tic-Tac-Toe PWA(Progressive Web App) implementation using Angular.',
                    bold: true,
                },
                { text: 'A 2 player game.', bold: false },
            ],
            screenshots: {
                valid: true,
                list: [
                    'assets/screenshots/T3PWA/t3_1.jpg',
                    'assets/screenshots/T3PWA/t3_2.jpg',
                    'assets/screenshots/T3PWA/t3_3.jpg',
                    'assets/screenshots/T3PWA/t3_4.jpg',
                    'assets/screenshots/T3PWA/t3_5.jpg',
                ],
            },
        },
        {
            id: 4,
            selected: false,
            link_available: true,
            page: 'project/4',
            category: ['React', 'Web', 'Web: Fullstack'],
            title: 'Library-GraphQL',
            logo: 'assets/images/library-gql.png',
            info: "A React application that uses GraphQL for storing and fetching book's/author's details using MongoDB.",
            link: 'https://github.com/tanaytoshniwal/Library-GraphQL',
            git: true,
            stack: [{ name: 'React.js' }, { name: 'GraphQL' }, { name: 'MongoDB' }, { name: 'Node.js' }, { name: 'Apollo' }],
            keywords: [{ name: 'GraphQL' }, { name: 'React.js' }, { name: 'API' }],
            details: [
                {
                    text: "A React application that uses GraphQL for storing and fetching book's/author's details using MongoDB.",
                    bold: true,
                },
                { text: 'Used MongoDB, Mlab as the database', bold: false },
                { text: 'Used GraphQL for the APIs', bold: false },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/library-gql.png'],
            },
        },
        {
            id: 26,
            selected: false,
            category: ['Java SE'],
            link_available: true,
            title: 'SnakeAndLadders',
            logo: 'assets/screenshots/SnakeAndLadders/snake.jpg',
            info: 'Implementation of Snake and Ladders Game using Java CLI.',
            link: 'https://github.com/tanaytoshniwal/SnakeAndLadders',
            page: 'project/26',
            git: true,
            stack: [{ name: 'Java SE' }, { name: 'JDK' }],
            keywords: [{ name: 'Snake and Ladders' }, { name: 'CLI Game' }, { name: 'Game Algorithms' }],
            details: [
                {
                    text: 'Implementation of Snake and Ladders Game using Java CLI.',
                    bold: true,
                },
                { text: 'A 2 to 4 players game.', bold: false },
            ],
            screenshots: {
                valid: true,
                list: ['assets/screenshots/SnakeAndLadders/snake.jpg'],
            },
        },
        {
            id: 7,
            selected: false,
            page: 'project/7',
            category: ['Android'],
            title: 'MegaTicTacToe',
            link_available: true,
            logo: 'assets/images/MT3.jpg',
            info: 'A two player game having 9x9 grid of simple tic-tac-toe game',
            git: true,
            link: 'https://github.com/tanaytoshniwal/MegaTicTacToe',
            stack: [{ name: 'Android Native Application' }, { name: 'Android Studio' }, { name: 'Android SDK' }],
            keywords: [{ name: '2 Player Mobile Game' }],
            details: [
                {
                    text: 'A two player game having 9x9 grid of simple tic-tac-toe game.',
                    bold: true,
                },
                { text: 'The application is built using Android Studio', bold: false },
                {
                    text: 'Includes new rules like directing the moves of other player, and many more.',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/screenshots/MT3/mt3_1.jpeg', 'assets/screenshots/MT3/mt3_2.jpeg', 'assets/screenshots/MT3/mt3_3.jpeg'],
            },
        },
        {
            id: 8,
            selected: false,
            page: 'project/8',
            title: 'TempoTap',
            category: ['Java SE'],
            link_available: true,
            logo: 'assets/images/TempoTap.gif',
            info: 'Java Application for generating tempo on tap frequency. Useful tool for Musical Instrument Players.',
            git: true,
            link: 'https://github.com/tanaytoshniwal/TempoTap',
            stack: [{ name: 'Java SE' }, { name: 'JDK' }],
            keywords: [{ name: 'Musician Software' }, { name: 'Core Java' }],
            details: [
                {
                    text: 'Java SE Application for Metronome creation on User Tapping Frequency',
                    bold: true,
                },
                { text: 'The application is built using Java SE', bold: false },
                {
                    text: 'Calculates the frequency of tapping and generate 4 beat metronome',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/TempoTap.gif'],
            },
        },
        {
            id: 9,
            selected: false,
            page: 'project/9',
            link_available: true,
            title: 'Skin-Sense',
            category: ['Android', 'Android: Fullstack'],
            logo: 'assets/images/skin-sense.png',
            info: 'Melanoma Skin Cancer Detection Android Application using Transfer Learning.',
            git: true,
            link: 'https://github.com/tanaytoshniwal/Skin-Sense',
            stack: [
                { name: 'Android Native Application' },
                { name: 'Android SDK' },
                { name: 'Firebase Authentication' },
                { name: 'Google Maps API' },
                { name: 'Tensorflow' },
            ],
            keywords: [{ name: 'Skin Cancer Classification' }, { name: 'Authentication' }, { name: 'Local Storage' }],
            details: [
                {
                    text: 'An Android Application for Skin Cancer Classification',
                    bold: true,
                },
                { text: 'Won Hitsathon v2.0 with this project', bold: true },
                {
                    text: 'Used Google Maps API for nearby Hospital Search',
                    bold: false,
                },
                {
                    text: 'Used Firebase Authentication with local data storage',
                    bold: false,
                },
                {
                    text: 'Used Inception v3 (mobilenet_100_224) Model for Classification',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: [
                    'assets/screenshots/SkinSense/s1.png',
                    'assets/screenshots/SkinSense/s2.png',
                    'assets/screenshots/SkinSense/s3.png',
                    'assets/screenshots/SkinSense/s4.png',
                    'assets/screenshots/SkinSense/s5.png',
                    'assets/screenshots/SkinSense/s6.png',
                    'assets/screenshots/SkinSense/s7.png',
                ],
            },
        },
        {
            id: 10,
            selected: false,
            page: 'project/10',
            link_available: true,
            title: 'TODO',
            category: ['Web', 'Angular', 'Web: Fullstack'],
            logo: 'assets/images/todo.png',
            info: 'A Todo List app built using MEAN Stack. Project is currently Live',
            git: true,
            link: 'https://github.com/tanaytoshniwal/TODO',
            stack: [{ name: 'MongoDB' }, { name: 'Angular 6' }, { name: 'Express.js' }, { name: 'Node.js' }],
            keywords: [{ name: 'MEAN Stack' }, { name: 'Live Application' }, { name: 'FullStack WebApp' }],
            details: [
                {
                    text: 'A Todo List app built using MEAN Stack. Project is currently Live',
                    bold: true,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/todo.png'],
            },
        },
        {
            id: 11,
            selected: false,
            link_available: true,
            page: 'project/11',
            category: ['Android'],
            title: 'RSSFeed',
            logo: 'assets/images/rssfeed.png',
            info: 'RSS Feed Android Application to display news from given URL and display it to the user. Application also works Offline',
            git: true,
            link: 'https://github.com/tanaytoshniwal/RSSFeed',
            stack: [{ name: 'Android Native Application' }, { name: 'Android SDK' }],
            keywords: [{ name: 'RSS Data' }, { name: 'Offline Storage' }, { name: 'Serialization' }],
            details: [
                {
                    text: 'RSS Feed Android Application to display news from given url and display it to the user',
                    bold: true,
                },
                {
                    text: 'Fetches the RSS Feeds from URL and Display it to user, with accessible links',
                    bold: false,
                },
                {
                    text: 'Implemented Offline Storage using Java Serialization for Offline Compatibility',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/rssfeed.png'],
            },
        },
        {
            id: 12,
            selected: false,
            page: 'project/12',
            category: ['Angular', 'Web'],
            title: 'GameOfFlags',
            link_available: true,
            logo: 'assets/images/gof.png',
            info: 'Game of Flags Quiz application with timer functionality built using Angular 6. This project is currently live.',
            git: true,
            link: 'https://github.com/tanaytoshniwal/GameOfFlags',
            stack: [{ name: 'Angular 6' }, { name: 'Bootstrap' }],
            keywords: [{ name: 'MEAN Stack' }, { name: 'Live Application' }, { name: 'Web Game' }],
            details: [
                {
                    text: 'A simple quiz on Flags with timer functionality and score board.',
                    bold: true,
                },
                {
                    text: 'Interactive UI Design for an Online Quiz Game on Angular 6',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/gof.png'],
            },
        },
        {
            id: 13,
            selected: false,
            page: 'project/13',
            category: ['Python'],
            title: 'Image-Processing',
            link_available: true,
            logo: 'assets/images/ip.png',
            info: 'Some Programs and my Implementation for Image processing in Open CV using Python',
            git: true,
            link: 'https://github.com/tanaytoshniwal/Image-Processing',
            stack: [{ name: 'Python' }, { name: 'Open CV' }],
            keywords: [{ name: 'Color Detection' }, { name: 'Color Detection and Tracking' }],
            details: [
                {
                    text: 'Some Programs and my Implementation for Image processing in Open CV using Python',
                    bold: true,
                },
                {
                    text: 'Implemented Color Detection with Tracking for Autonomous Navigation of Rover at SRM RUDRA',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/ip.png'],
            },
        },
        {
            id: 14,
            selected: false,
            page: 'project/14',
            title: 'Chat-Java',
            link_available: true,
            category: ['Java SE'],
            logo: 'assets/images/chat.gif',
            info: 'A two way chat program written in JAVA using Networking and Socket Programming.',
            git: true,
            link: 'https://github.com/tanaytoshniwal/Chat-Java',
            stack: [{ name: 'Java SE' }, { name: 'Socket Programming' }],
            keywords: [{ name: 'Networking' }, { name: 'Two Way Chat' }],
            details: [
                {
                    text: 'A two way chat program written in JAVA using Networking.',
                    bold: true,
                },
                { text: 'Implemented Terminal and GUI Versions', bold: false },
                { text: 'Used Socket Programming', bold: false },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/chat.gif'],
            },
        },
        {
            id: 15,
            selected: false,
            page: 'project/15',
            title: 'GameOfLife',
            link_available: true,
            category: ['Java SE'],
            logo: 'assets/images/gol.jpg',
            info: "Implementation of Conway's Game of life using Java swing components and MultiThreading.",
            git: true,
            link: 'https://github.com/tanaytoshniwal/GameOfLife',
            stack: [{ name: 'Java SE' }, { name: 'Multi-Threading' }],
            keywords: [{ name: 'Multi-Threading' }, { name: 'Java GUI' }],
            details: [
                { text: "My Implementation of Conway's Game Of Life", bold: true },
                {
                    text: 'Used Multi-Threading for the working of application',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/gol.jpg'],
            },
        },
        {
            id: 16,
            selected: false,
            page: 'project/16',
            title: 'Chess',
            link_available: true,
            category: ['Java SE'],
            logo: 'assets/images/chess.gif',
            info: 'A chess computer vs human game made in Java using Alpha-Beta Pruning',
            git: true,
            link: 'https://github.com/tanaytoshniwal/Chess',
            stack: [{ name: 'Alpha-Beta Pruning' }, { name: 'Java SE' }],
            keywords: [{ name: 'Chess Human vs Computer' }, { name: 'Java GUI' }],
            details: [
                {
                    text: 'A Simple Implementation of Chess Game, Human vs Computer',
                    bold: true,
                },
                { text: 'Used Alpha-Beta Pruning Algorithm', bold: false },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/chess.gif'],
            },
        },
        {
            id: 17,
            link_available: true,
            selected: false,
            page: 'project/17',
            title: 'Git-GUI',
            category: ['Java SE'],
            logo: 'assets/images/gitui.gif',
            info: 'A Git-GUI Implementation in Java SE that executes CMD commands to execute Git Commands',
            git: true,
            link: 'https://github.com/tanaytoshniwal/Git-GUI',
            stack: [{ name: 'Git' }, { name: 'Java SE' }],
            keywords: [{ name: 'Git GUI' }, { name: 'Java GUI' }],
            details: [
                { text: 'A Git-GUI Implementation in Java SE', bold: true },
                {
                    text: 'Implemented basic git operations like commit, add, push, pull, etc.',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/gitui.gif'],
            },
        },
        {
            id: 18,
            selected: false,
            link_available: true,
            page: 'project/18',
            title: 'ShakeMe',
            category: ['Android'],
            logo: 'assets/images/shakeme.gif',
            info: 'An Android application to play the Gif with Mp3 sound "BATMAN ON DRUGS" on phone Shake',
            git: true,
            link: 'https://github.com/tanaytoshniwal/ShakeMe',
            stack: [{ name: 'Android Native Application' }, { name: 'Android SDK' }],
            keywords: [{ name: 'Shake Detection' }, { name: 'Accelerometer' }],
            details: [
                {
                    text: 'An Android application to play the Gif with Mp3 sound "BATMAN ON DRUGS" on phone Shake',
                    bold: true,
                },
                {
                    text: "Used Device's Accelerometer for Shake Detection",
                    bold: false,
                },
                { text: 'Used Glide Library for playing Gif', bold: false },
                { text: 'Replica of "Shake-Me" by @PiJoules', bold: false },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/shakeme.gif'],
            },
        },
        {
            id: 19,
            selected: false,
            page: 'project/19',
            category: ['Android'],
            title: 'Tic-Tac-Toe-Android',
            link_available: true,
            logo: 'assets/images/ttt.png',
            info: 'Implementation of Tic-Tac-Toe Game in Android.',
            git: true,
            link: 'https://github.com/tanaytoshniwal/Tic-Tac-Toe-Android-',
            stack: [{ name: 'Android Native Application' }, { name: 'Android SDK' }],
            keywords: [{ name: 'Tic Tac Toe' }, { name: 'Two Player Game' }],
            details: [
                {
                    text: 'The traditional and very famous game "Tic-Tac-Toe" in android',
                    bold: true,
                },
                { text: '2 Player Game implemented in Android Studio', bold: false },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/ttt.png'],
            },
        },
        {
            id: 20,
            selected: false,
            link_available: true,
            page: 'project/20',
            title: 'IonicFirebase',
            category: ['Ionic Framework', 'Hybrid App', 'Hybrid App: Fullstack'],
            logo: 'assets/images/ionicfirebase.png',
            info: 'Hybrid application built on Ionic framework using Firebase services like Firebase Firestore and Firebase Authentication',
            git: true,
            link: 'https://github.com/tanaytoshniwal/IonicFirebase',
            stack: [
                { name: 'Ionic 4' },
                { name: 'Firebase' },
                { name: 'CRUD Operations' },
                { name: 'Firestore' },
                { name: 'Firebase Authentication' },
            ],
            keywords: [{ name: 'CRUD' }, { name: 'Firestore Operations' }, { name: 'Authentication' }],
            details: [
                {
                    text: 'Hybrid application built on Ionic Framework using Firestore Database and Firebase Authentication',
                    bold: true,
                },
                {
                    text: 'Implemented basic CRUD Operations for specific user',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/ionicfirebase.png'],
            },
        },
        {
            id: 21,
            selected: false,
            page: 'project/21',
            link_available: true,
            category: ['Machine Learning'],
            title: '100DaysOfMLCode',
            logo: 'assets/images/100DaysOfMLCode.jpg',
            info: 'This Repository is for #100DaysOfMLCode Challenge proposed by Siraj Raval',
            git: true,
            link: 'https://github.com/tanaytoshniwal/100DaysOfMLCode',
            stack: [{ name: 'Python' }, { name: 'Jupyter Notebook' }],
            keywords: [{ name: '100 Days Of ML Code' }],
            details: [
                {
                    text: '100 Days of ML Code is Initiative of Siraj Raval',
                    bold: true,
                },
                { text: 'Completed 76 days of 100 days', bold: false },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/100DaysOfMLCode.jpg'],
            },
        },
        {
            id: 22,
            selected: false,
            page: 'project/22',
            link_available: true,
            category: ['Web', 'Angular', 'Web: Fullstack'],
            title: 'MEAN-Stack',
            logo: 'assets/images/meanstack.png',
            info: 'Practice of MEAN Stack and some Mini-Projects',
            git: true,
            link: 'https://github.com/tanaytoshniwal/MEAN-Stack',
            stack: [{ name: 'MongoDB' }, { name: 'Angular 6' }, { name: 'Express.js' }, { name: 'Node.js' }],
            keywords: [{ name: 'Practice Projects' }],
            details: [
                { text: 'Code and Assignments of my MEAN Stack Practice', bold: true },
                {
                    text: 'The project is deployed here https://mean-stack-29ad2.firebaseapp.com/',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/meanstack.png'],
            },
        },
        {
            id: 23,
            selected: false,
            link_available: true,
            page: 'project/23',
            category: ['Ionic Framework', 'Hybrid App', 'Hybrid App: Fullstack'],
            title: 'Ionic-Practice-Mini-Projects',
            logo: 'assets/images/ionicpractice.png',
            info: 'Practice of Ionic Framework and Mini-Project',
            git: true,
            link: 'https://github.com/tanaytoshniwal/Ionic-Practice',
            stack: [{ name: 'MongoDB' }, { name: 'Angular 6' }, { name: 'Express.js' }, { name: 'Node.js' }, { name: 'Ionic Framework' }],
            keywords: [{ name: 'Practice Projects' }],
            details: [
                {
                    text: 'Code and Assignments of my Ionic Framework Practice',
                    bold: true,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/images/ionicpractice.png'],
            },
        },
        {
            id: 24,
            selected: true,
            page: 'project/24',
            link_available: true,
            title: 'Portfolio',
            category: ['Angular', 'Web', 'Web: Fullstack'],
            logo: 'assets/images/Portfolio.jpg',
            info: 'My Portfolio site, built from scratch using MEAN Stack.',
            link: 'https://tanaytoshniwal.me',
            git: false,
            stack: [{ name: 'Angular' }, { name: 'Node.js' }, { name: 'Express.js' }, { name: 'Firebase' }, { name: 'REST API' }, { name: 'Heroku' }],
            keywords: [
                { name: 'PWA' },
                { name: 'Portfolio' },
                { name: 'SEO' },
                { name: 'Lazy Loading' },
                { name: 'Two Themed' },
                { name: 'Material Design Lite' },
                { name: 'Firebase' },
            ],
            details: [
                { text: 'Built my own Portfolio site', bold: true },
                {
                    text: 'A fully responsive webapp built from scratch using MEAN Stack',
                    bold: false,
                },
                {
                    text: 'Designed a two themed site for a better user interface with easy theme switch button',
                    bold: false,
                },
                { text: 'Dynamic data rendering from JSON objects.', bold: false },
                {
                    text: 'Implemented Lazy Loading to improve efficiency and faster loading of each route',
                    bold: true,
                },
                { text: 'Implemented various custom UI Components', bold: false },
                {
                    text: 'Built a contact page for easy communication through emails',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: [
                    'assets/screenshots/portfolio/portfolio_1.jpg',
                    'assets/screenshots/portfolio/portfolio_2.jpg',
                    'assets/screenshots/portfolio/portfolio_3.jpg',
                    'assets/screenshots/portfolio/portfolio_4.jpg',
                    'assets/screenshots/portfolio/portfolio_5.jpg',
                    'assets/screenshots/portfolio/portfolio_6.jpg',
                    'assets/screenshots/portfolio/portfolio_7.jpg',
                    'assets/screenshots/portfolio/portfolio_8.jpg',
                    'assets/screenshots/portfolio/portfolio_9.jpg',
                    'assets/screenshots/portfolio/portfolio_10.jpg',
                    'assets/screenshots/portfolio/portfolio_11.jpg',
                ],
            },
        },
        {
            id: 25,
            link_available: true,
            selected: true,
            title: 'Blog site',
            category: ['React', 'Web', 'Web: Fullstack'],
            logo: 'assets/images/Blogsite.jpg',
            info: 'My Blog site, built from scratch using MERN Stack. It fetches the data from my Medium Channel and displays all the blogs in an interactive way.',
            link: 'https://blogs.tanaytoshniwal.me',
            page: 'project/25',
            git: false,
            stack: [
                { name: 'React' },
                { name: 'create-react-app' },
                { name: 'Node.js' },
                { name: 'Express.js' },
                { name: 'Firebase' },
                { name: 'REST API' },
                { name: 'Heroku' },
            ],
            keywords: [{ name: 'PWA' }, { name: 'Blogsite' }, { name: 'Material Design Lite' }, { name: 'Firebase' }, { name: 'Medium' }],
            details: [
                {
                    text: 'Built my own Blog site to fetch blogs from my Medium Channel',
                    bold: true,
                },
                {
                    text: 'A fully responsive webapp built from scratch using MERN Stack',
                    bold: false,
                },
                {
                    text: 'Site fetches all the blogs dynamically and displays the content in an interactive way',
                    bold: false,
                },
                {
                    text: 'Implemented easy overview of the content through the website itself',
                    bold: false,
                },
                {
                    text: 'Built a contact page for easy communication through emails',
                    bold: false,
                },
            ],
            screenshots: {
                valid: true,
                list: ['assets/screenshots/blogs/blog_1.jpg', 'assets/screenshots/blogs/blog_2.jpg', 'assets/screenshots/blogs/blog_3.jpg'],
            },
        },
    ],
};
