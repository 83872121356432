import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CookieService } from 'ngx-cookie-service';

import { NgxPaginationModule } from 'ngx-pagination';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { YoutubeComponent } from './youtube/youtube.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [AppComponent, YoutubeComponent],
    imports: [
        BrowserModule,
        NgxPaginationModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        ScrollToModule.forRoot(),
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
    ],
    providers: [
        CookieService,
        {
            provide: 'externalUrlRedirectResolver',
            useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
                window.location.href = (route.data as any).externalUrl;
            },
        },
        Title,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
