import { AfterViewInit, Component, Input } from '@angular/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { ThemeService } from 'src/app/theme.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements AfterViewInit {
    @Input('heading') heading: string;
    @Input('subHeading') subHeading?: string;
    @Input('cover') cover?: boolean;

    constructor(public themeService: ThemeService, private scroll: ScrollToService) {}

    ngAfterViewInit() {
        const config: ScrollToConfigOptions = {
            target: 'top',
        };

        this.scroll.scrollTo(config);
    }
}
