import { Companies } from '../data.service';

export const experience: Array<Companies> = [
    {
        image: 'assets/images/blinkit.png',
        alt: 'Blinkit',
        name: 'Blinkit',
        link: 'https://blinkit.com/',
        role: 'Software Development Engineer 1',
        duration: 'September 2021 - Present',
        data: [
            [
                'Working with the core web team in development of <a href="https://blinkit.com/" target="_blank">Blinkit</a> website.',
                'Implemented auto-read OTP on mWeb, reducing the login drops by <strong>6.09%</strong> and fastening the login process by <strong>37%</strong>.',

                'Core Technologies: React, React Native, Typescript.',
            ],
        ],
    },
    {
        image: 'assets/images/TCS.png',
        alt: 'Tata Consultancy Services',
        name: 'Tata Consultancy Services',
        link: 'https://www.tcs.com/',
        role: 'Software Developer',
        duration: 'October 2020 – September 2021',
        data: [
            ['Worked on the development of Microsoft D365 Customer Voice.', 'Core Technologies: ReactJs, Typescript.'],
            [
                'Implemented OAuth app based security scan with automated access token renewal while the scan is running, having significant impact over PAT based scans.',
                'Working on development of <a href="https://github.com/azsk/ADOScanner" target="_blank">ADO Scanner (Azure DevOps)</a> with Microsoft.',
                'Core Technologies: PowerShell, Azure Cloud Services.',
            ],
        ],
    },
    {
        image: 'assets/images/AnantaGlobalLogo.png',
        alt: 'Ananta Hotels & Resorts',
        name: 'Ananta Hotels & Resorts',
        link: 'https://www.anantahotels.com/',
        role: 'Freelance Developer',
        duration: 'August 2020 – Present',
        data: [
            [
                'Worked on membership management website built using React.js, Redux, Firebase Firestore and Firebase Authentication.',
                'Implemented efficient authorization for 5 admins, 9 staff members and 10K+ customers to access the website.',
                'Admins can manage their plans and benefits, can add/modify any customer and can view redeemed benefits filtered date-wise.',
                "Staff Members can redeem any customer's benefits at their resorts or hotels on request.",
                'Customers can view their membership details and their available/redeemed benefits.',
                'Website is live <a href="https://ananta-membership.web.app/login" target="_blank">here</a>.',
            ],
        ],
    },
    {
        image: 'assets/images/enappd.png',
        alt: 'Enappd',
        name: 'Enappd',
        link: 'https://enappd.com/',
        role: 'Full Stack Developer (<a href="https://en.wikipedia.org/wiki/MEAN_(software_bundle)" target="_blank">MEAN Stack</a>) Intern',
        duration: 'December 2018 – January 2019',
        data: [
            [
                'Worked on 4 live projects: 2 in Ionic 4, 2 in Angular 6, Node.js, Express.js & MongoDB.',
                'Developed Hybrid mobile Apps, backend Server and Responsive Web-Apps.',
                'Made IOS & Android version of ”<a href="https://store.enappd.com/product/ionic-4-woocommerce-starter/" target="_blank">Wish</a>” and ”Pomodoro”.',
                'About 60+ items sold of E-commerce Starter Application at <a href="https://store.enappd.com" target="_blank">enappd store</a>.',
                'Worked on Firebase, mlab, REST API architecture, Heroku, etc.',
            ],
        ],
    },
    {
        image: 'assets/images/saachitech.png',
        alt: 'Saachi Technologies',
        name: 'Saachi Technologies',
        link: 'https://www.saachitech.com/',
        role: 'Mobile Application Developer Intern',
        duration: 'March 2020 – May 2020',
        data: [
            [
                'Worked as a React Native Developer to develop various Full-Stack Mobile Applications.',
                'Delevoped hybrid apps using Firestore & Firebase Authentication.',
                'Used Node.js to develop REST APIs.',
            ],
        ],
    },
    {
        image: 'assets/images/youstart.jpg',
        alt: 'Youstart Labs',
        name: 'Youstart, Jaipur',
        link: 'https://youstartlabs.in/',
        role: 'Mentor (MERN Stack)',
        duration: 'June 2019 – July 2019',
        data: [
            [
                'Mentored about 20-25 students with their projects in ReactJs, MongoDB, ExpressJs, NodeJs.',
                'Worked on 2 live projects built on MERN Stack.',
                'Built and deployed an <a href="https://onlinediagnosis-cbce9.firebaseapp.com/" target="_blank">Online Diagnosis WebApp</a> using Infermedica\'s API.',
                'Built and deployed a <a href="https://musicsearch-77514.firebaseapp.com/" target="_blank">Music Search and Lyrics Finding WebApp</a> using Apple Music\'s API.',
                'Bootcamp Repository can be found <a href="https://github.com/abhishekrathore/fullstackbootcamp2019" target="_blank">here</a>.',
            ],
        ],
    },
    {
        image: 'assets/images/codingninjas.jpg',
        alt: 'Coding Ninjas',
        name: 'Coding Ninjas',
        link: 'https://www.codingninjas.in/',
        role: 'Teaching Assistant - Competitive Programming',
        duration: 'June 2019 – November 2019',
        data: [
            [
                "Worked as a Teaching Assistant for Coding Ninja's Competitive Programming Course.",
                'Assisted around 400+ students of 3 different batches.',
            ],
        ],
    },
    {
        image: 'assets/images/webarch.jpg',
        alt: 'Webarch',
        name: 'Webarch, Chennai',
        link: 'https://webarchsrm.com/',
        role: 'Application Development Lead',
        duration: 'Feb 2018 – April 2020',
        data: [
            [
                'Worked as a Application Development Lead',
                'Worked on various Hybrid mobile Apps and Native Apps for University Related Projects.',
                'Used Firebase, Android Studio, Ionic Framework, mLab.',
            ],
            ['Worked as a App Dev team member.'],
        ],
    },
    {
        image: 'assets/images/rudra.png',
        alt: 'SRM Rudra',
        name: 'SRM Rudra, Chennai',
        link: 'http://srmrudra.com/',
        role: 'Coding Team Member',
        duration: 'Aug 2017 – Jan 2018',
        data: [
            [
                'Built autonomous navigation and control system for Mars-Rover that annually competes in <a href="http://urc.marssociety.org/" target="_blank">URC-University Rover Challenge</a>.',
                'Worked on OpenCV with Python, Raspberry Pi and Arduino.',
            ],
        ],
    },
];
