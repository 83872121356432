import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { ThemeService } from './theme.service';
import { HttpClient } from '@angular/common/http';

import { faFeatherAlt, faCode, faComments, faUser, faCloudDownloadAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { NavItem, DataService } from './data.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    faFeatherAlt = faFeatherAlt;
    faGithub = faGithub;
    faEnvelope = faEnvelope;
    faCode = faCode;
    faTwitter = faTwitter;
    faLinkedin = faLinkedin;
    faUser = faUser;
    faComments = faComments;
    faCloudDownloadAlt = faCloudDownloadAlt;

    showText: boolean = true;
    footerHoverText: string = '';

    title = 'app';
    date: number;
    hour: number;

    navItems: Array<NavItem> = [];

    constructor(
        private router: Router,
        private scroll: ScrollToService,
        public themeService: ThemeService,
        public dataService: DataService,
        private titleService: Title
    ) {
        this.navItems = dataService.menu;
        let x: any = new Date();
        this.date = x.getFullYear();
        this.hour = x.getHours();
        if (this.hour >= 6 && this.hour <= 18) {
            this.themeService.flag = false;
            this.theCheckbox = false;
        } else {
            this.themeService.flag = true;
            this.theCheckbox = true;
        }
        document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                this.titleService.setTitle('(1) See you soon!');
            } else {
                this.titleService.setTitle('Tanay Toshniwal');
            }
        });
    }

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
        event.preventDefault();
    }

    ngOnInit(): void {
        let chunk = localStorage.getItem('theme');
        if (chunk === 'true') {
            this.themeService.flag = true;
            this.theCheckbox = true;
        } else if (chunk === 'false') {
            this.themeService.flag = false;
            this.theCheckbox = false;
        }
    }

    changeText = (text) => {
        this.footerHoverText = text;
    };

    showFooterText = (val, text) => {
        this.showText = val;
        this.changeText(text);
    };

    buttons = [];
    fabTogglerState = 'inactive';
    state = false;
    comment = '';
    theCheckbox = false;

    toggleVisibility(e) {
        this.themeService.flag = e.target.checked;
        localStorage.setItem('theme', e.target.checked);
    }

    toogle(e) {
        if (e.target.outerText === ' Blogs' || e.target.innerHTML === ' Blogs') window.open('https://mysticalcoder.com/', '__blank');
        document.querySelector('.mdl-layout__drawer').addEventListener(
            'click',
            function () {
                document.querySelector('.mdl-layout__obfuscator').classList.remove('is-visible');
                this.classList.remove('is-visible');
            },
            false
        );
    }

    showItems() {
        this.fabTogglerState = 'active';
        this.buttons = [
            { icon: 'sms', comment: 'Message Me!', state: false },
            { icon: 'cloud_download', comment: 'Download My Resume!', state: false },
            { icon: 'roofing', comment: 'Home!', state: false },
            { icon: 'expand_less', comment: 'Go to Top!', state: false },
        ];
    }

    hideItems() {
        this.fabTogglerState = 'inactive';
        this.buttons = [];
        this.state = false;
        this.comment = '';
    }

    jsvoid() {
        void 0;
    }

    update(i, b) {
        if (b) {
            this.buttons[i].state = true;
            this.comment = this.buttons[i].comment;
        } else {
            this.buttons[i].state = false;
            this.comment = '';
        }
    }

    fab(i) {
        if (i == 3) {
            const config: ScrollToConfigOptions = {
                target: 'top',
            };

            this.scroll.scrollTo(config);
        }
        if (i == 2) this.router.navigate(['/home']);
        if (i == 1) window.open('./assets/tanay-toshniwal-resume.pdf', '_blank');
        if (i == 0) this.router.navigate(['/contact']);
        this.hideItems();
    }

    onToggleFab(b) {
        if (this.buttons.length == 0 && b) this.showItems();
        else this.hideItems();
    }
}
